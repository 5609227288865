// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export default function debounce(func, wait, options) {
    var timeout;
 
    var leading = options.leading || false;
    var trailing = options.trailing === undefined ? true : options.trailing;
  
    return function () {

        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (trailing) func.apply(context, args);
        };
        var callNow = leading && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

