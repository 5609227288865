import $ from 'jquery'
import debounce from './libraries/debounce';

// import { MY_TOPIC } from "./components/EventTypes"; 



 
/**
* documentation for pubsub module here
https://github.com/mroderick/PubSubJS/

use like this:
  
//handler
var mySubscriber = function (msg, data) { 
   console.log(msg, data); 
}; 
 
//listen for a topic
var token = PubSub.subscribe(MY_TOPIC, mySubscriber);

// publish a topic asynchronously 
PubSub.publish(MY_TOPIC, ['hello world!','test']);

**/



window.abano = window.abano || {};

var vh = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

var menuVhFix__DEBOUNCED_WINDOW_RESIZE = debounce(function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}, 100, { trailing: true });
$(window).on('resize', menuVhFix__DEBOUNCED_WINDOW_RESIZE);


const loadRules = {
    documentContains: (selector) => { return document.querySelector(selector) != null }
}

abano.navigation = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-nav')) {

                import(/* webpackChunkName: "abano.navigation" */ './LazyComponents/abano.navigation').then(module => {
                    module.default();
                });
            }
        },
    };
}());
abano.navigation.init();



abano.miniSearch = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-mini-search')) {
                import(/* webpackChunkName: "abano.miniSearch" */ './LazyComponents/abano.miniSearch').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.miniSearch.init();


abano.bookNow = (function () {
    return {
        init() {
            if (abano.config && abano.config.practiceDirectory) {
                import(/* webpackChunkName: "abano.bookNow" */ './LazyComponents/abano.bookNow').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.bookNow.init();


abano.preferredPractice = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-practice-widget], [data-practice-details-label]')) {

                import(/* webpackChunkName: "abano.preferredPractice" */ './LazyComponents/abano.preferredPractice').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.preferredPractice.init();




//this will not work well with 2 pagination areas on the same page both will update the url.
//if we need this feature a work around is required.
abano.mainSearch = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.pagination-results')) {
                import(/* webpackChunkName: "abano.mainSearch" */ './LazyComponents/abano.mainSearch').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.mainSearch.init();

abano.careersSearch = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-careers-search-bar"]')) {
                import(/* webpackChunkName: "abano.careersSearchBar" */ './LazyComponents/abano.careersSearchBar').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.careersSearch.init();

abano.careersJobList = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-careers-job-list"]')) {
                import(/* webpackChunkName: "abano.careersJobListr" */ './LazyComponents/abano.careersJobList').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.careersJobList.init();

abano.toggleCollapse = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-toggle-text]')) {

                import(/* webpackChunkName: "abano.toggleCollapse" */ './LazyComponents/abano.toggleCollapse').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.toggleCollapse.init();


//wrap the bootstrap modules we need so that we can lazy load with webpack.
//the bootstrap plugins ars self contained so we don't actually need to do any callback.
abano.collapse = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-toggle="collapse"], [data-depends="collapse"]')) {
                import(/* webpackChunkName: "abano.bsCollapse" */ './LazyComponents/abano.bsCollapse')
            }
            if (loadRules.documentContains('[data-toggle="tab"]')) {
                import(/* webpackChunkName: "abano.bsTab" */ './LazyComponents/abano.bsTab')

            }
        }
    }
})().init();

abano.scrollBannerAlert = (function () {
    return {
       
        init: function () {
            if (loadRules.documentContains('[data-component="c-sliding-banner-alert"]')) {

                import(/* webpackChunkName: "abano.sliderAlert" */ './LazyComponents/abano.sliderAlert').then(module => {
                    module.default();
                });
            }
        }
    };

}());
abano.scrollBannerAlert.init();

abano.ribbon = (function () {
    return {
        practiceRibbon: function () { return practiceRibbon }, //todo: this wont work.
        init: function () {
            if (loadRules.documentContains('.c-ribbon')) {

                import(/* webpackChunkName: "abano.ribbon" */ './LazyComponents/abano.ribbon').then(module => {
                    module.default();
                });
            }
        }
    };

}());
abano.ribbon.init();

 abano.umbracoForms = (function () {
    var forms = $('.umbraco-forms-form form');
    return {
        init: function () {
            if (forms.length) {
                import(/* webpackChunkName: "abano.umbracoForms" */ './LazyComponents/abano.umbracoForms').then(module => {
                    module.default(forms);
                });
            }
        }
    }
}());
abano.umbracoForms.init();

abano.parallax = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-flourish--angle.c-flourish--parallax')) {

                import(/* webpackChunkName: "abano.parallax" */ './LazyComponents/abano.parallax').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.parallax.init();

abano.practiceMiniFinder = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-practice-minifinder]')) {

                import(/* webpackChunkName: "abano.practiceMiniFinder" */ './LazyComponents/abano.practiceMiniFinder').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.practiceMiniFinder.init();



abano.meganav = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-meganav--trigger')) {

                import(/* webpackChunkName: "abano.megaNavigation" */ './LazyComponents/abano.megaNavigation').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.meganav.init();

abano.testimonials = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.slick-testimonials')) {

                import(/* webpackChunkName: "abano.testimonials" */ './LazyComponents/abano.testimonials').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.testimonials.init();

abano.textClamp = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-clamp-text')) {

                import(/* webpackChunkName: "abano.textClamp" */ './LazyComponents/abano.textClamp').then(module => {
                    module.default();
                });
            }
        }
    }
}());

$(window).on("load", function (e) {
    abano.textClamp.init();
})

abano.resultsimages = (function () {

    $(window).on("load", function (e) {
        abano.resultsimages.init();
    })

    return {
        init: function () {
            if (loadRules.documentContains('.result-image-item')) {


                import(/* webpackChunkName: "abano.resultsimages" */ './LazyComponents/abano.resultsImages').then(module => {
                    module.default();
                });

            }
        }
    }
}());




abano.gallerySlider = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-slider.slick-gallery')) {

                import(/* webpackChunkName: "abano.gallerySlider" */ './LazyComponents/abano.gallerySlider').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.gallerySlider.init();

abano.scrollBanner = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-scroll-banner')) {
                import(/* webpackChunkName: "abano.scrollBanner" */ './LazyComponents/abano.scrollBanner').then(module => {
                    module.default();
                });
            }
        }
    }
}()); 
abano.scrollBanner.init();

abano.imageSlider = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-image-slider')) {
                import(/* webpackChunkName: "abano.imageSlider" */ './LazyComponents/abano.imageSlider').then(module => {
                    module.default()
                })
            }
        }
    }
}());
abano.imageSlider.init(); 

abano.promoSlider = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-slider.slick-promo')) {

                import(/* webpackChunkName: "abano.promoSlider" */ './LazyComponents/abano.promoSlider').then(module => {
                    module.default();
                });
            }

        }
    }

}());
abano.promoSlider.init();

abano.articleSlider = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.c-slider.slick-news')) {

                import(/* webpackChunkName: "abano.articleSlider" */ './LazyComponents/abano.articleSlider').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.articleSlider.init();

abano.bookNowButton = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-book-now]')) {

                import(/* webpackChunkName: "abano.bookNowButton" */ './LazyComponents/abano.bookNowButton').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.bookNowButton.init();

abano.scrollJump = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('a[data-scroll-to], select[data-scroll-to]')) {

                import(/* webpackChunkName: "abano.scrollJump" */ './LazyComponents/abano.scrollJump').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.scrollJump.init();


abano.menu = (function () {

    return {
        init: function () {
            if (loadRules.documentContains('.menu-trigger button')) {

                import(/* webpackChunkName: "abano.menu" */ './LazyComponents/abano.menu').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.menu.init();

//todo: make sure this is correct.
abano.forms = (function () {

    return {
        init: function () {
            if (loadRules.documentContains('.practicepicker, .treatmentpicker')) {

                import(/* webpackChunkName: "abano.umbracoFormsDynamicFields" */ './LazyComponents/abano.umbracoFormsDynamicFields').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.forms.init();

//place for google maps to hook once it loads.
abano.maps = (function () {

    return {
        //the callback that google maps will run
        init: function () {
            if (loadRules.documentContains('[data-map]')) {

                import(/* webpackChunkName: "abano.maps" */ './LazyComponents/abano.maps').then(module => {
                    module.default();
                });
            }

        }
    };
}());
abano.maps.init();



abano.introReadMoreButton = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('.dynamic-introtext article')) {

                import(/* webpackChunkName: "abano.introReadMoreButton" */ './LazyComponents/abano.introReadMoreButton').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.introReadMoreButton.init();


abano.animations = (function () {

    return {
        init: function () {
            if (loadRules.documentContains('.animate')) {

                import(/* webpackChunkName: "abano.animations" */ './LazyComponents/abano.animations').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.animations.init();

abano.healthFund = (function () {

    return {
        init: function () {
            if (loadRules.documentContains('.c-health-fund-minifinder, [data-component="health-fund-controls"]')) {

                import(/* webpackChunkName: "abano.healthFund" */ './LazyComponents/abano.healthFund').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.healthFund.init();

abano.careers = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-careers"]')) {

                import(/* webpackChunkName: "abano.careers" */ './LazyComponents/abano.careers').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.careers.init();

abano.emergencyDentists = (function () {
    return {

        init: function () {
            if (loadRules.documentContains('[data-component="c-emergency-dentists"]')) {

                import(/* webpackChunkName: "abano.emergencyDentists" */ './LazyComponents/abano.emergencyDentists').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.emergencyDentists.init();


abano.locationOffers = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-location-offers"]')) {

                import(/* webpackChunkName: "abano.locationOffers" */ './LazyComponents/abano.locationOffers').then(module => {
                    module.default();
                });
            }
        }
    }
}());
abano.locationOffers.init();

abano.wellnessBlock = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-wellness-block"]')) {
                import(/* webpackChunkName: "abano.wellnessBlock" */ './LazyComponents/abano.wellnessBlock').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.wellnessBlock.init();


abano.accordionBlock = (function () {
    return {
        init: function () {
            if (loadRules.documentContains('[data-component="c-accordion-block"]')) {
                import(/* webpackChunkName: "abano.accordionBlock" */ './LazyComponents/abano.accordionBlock').then(module => {
                    module.default();
                });
            }
        }
    };
}());
abano.accordionBlock.init();